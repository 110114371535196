import * as types from '../actionTypes/auth';
import { toastError, toastSuccess } from '../../lib/toastHelper';

const initialState = {
  data: null,
  errorLogin: '',
  loading: false,
  user: null,
  hotel: null,
  hotels: [],
  loginSuccess: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        errorLogin: '',
        loading: true,
      };
    case types.LOGIN_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        data,
        errorLogin: '',
        loading: false,
        loginSuccess: true,
      };
    }
    case types.LOGIN_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        errorLogin: error,
        loading: false,
      };
    }
    case types.GET_INFO:
      return {
        ...state,
      };
    case types.GET_INFO_SUCCESS: {
      const { user, hotels } = action.payload.data;
      return {
        ...state,
        user,
        hotels,
      };
    }
    case types.GET_INFO_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
      };
    }
    case types.SET_HOTEL_ACTIVE: {
      return {
        ...state,
      };
    }
    case types.SET_HOTEL_ACTIVE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        hotel: data,
      };
    }
    case types.NOTIFY_HOTEL: {
      return {
        ...state,
      };
    }
    case types.NOTIFY_HOTEL_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        hotels: [...data],
      };
    }
    case types.UPDATE_ONBOARD_HOTEL_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        hotels: [...data],
      };
    }
    case types.UPDATE_CURRENCY_SETTING: {
      return {
        ...state,
      };
    }
    case types.UPDATE_CURRENCY_SETTING_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        hotel: {
          ...state.hotel,
          ...data 
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
