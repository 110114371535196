import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Redirect, Switch, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../Loader';
import {
  liveChatConstant,
  publicConstant,
  webviewConstant,
  dashboardConstant,
  inStayServiceConstant,
} from './RouterConstant';
import axiosService from '../../lib/axiosService';
import LiveChatRoutes from './LiveChatRoutes';
import PublicRoutes from './PublicRoutes';
import WebviewLayout from './WebviewRoutes';
import DashboardRoutes from './DashboardRoutes';
import InStayServiceRoutes from './InStayServiceRoutes';
import PageNotFound from '../../view/PageNotFound';
import PaymentSuccess from '../../view/Webview/Payment/Result/PaymentSuccess';
import Privacy from '../../view/Privacy';
import * as authActions from '../../store/actions/auth';
import { DASHBOARD_TYPE } from '../../constants';

function renderLiveChatRoutes() {
  let xhtml = null;
  xhtml = liveChatConstant.map(route => {
    return (
      <LiveChatRoutes
        component={route.component}
        exact={route.exact}
        key={route.id}
        path={`/:slug${route.path}`}
      />
    );
  });
  return xhtml;
}

function renderWebviewRoutes() {
  let xhtml = null;
  xhtml = webviewConstant.map(route => {
    return (
      <WebviewLayout
        component={route.component}
        exact={route.exact}
        key={route.id}
        path={route.path}
      />
    );
  });
  return xhtml;
}

function renderPublicRoutes() {
  let xhtml = null;
  xhtml = publicConstant.map(route => {
    return (
      <PublicRoutes
        component={route.component}
        exact={route.exact}
        key={route.id}
        path={route.path}
      />
    );
  });
  return xhtml;
}

function renderDashboard(hotel) {
  let xhtml = null;
  const parentRouter = [];
  const childRouter = [];
  const isAdmin = hotel.userRole.is_admin;
  let routes = [];
  if (isAdmin === 0) {
    routes = dashboardConstant.filter(item => item.isStaff);
  } else {
    routes = dashboardConstant;
  }
  routes.map(router => {
    if (router.child && router.child.length > 0) {
      router.child.map(child => childRouter.push(child));
    } else {
      parentRouter.push(router);
    }
    return '';
  });
  const routerNeedRender = [...parentRouter, ...childRouter];

  xhtml = routerNeedRender.map(route => {
    return (
      <DashboardRoutes
        component={route.component}
        exact={route.exact}
        key={route.id}
        path={`/:slug${route.path}`}
        type={DASHBOARD_TYPE.HOTEL_ROOMS}
      />
    );
  });
  return xhtml;
}

function renderInStayService() {
  let xhtml = null;
  const parentRouter = [];
  const childRouter = [];
  inStayServiceConstant.map(router => {
    if (router.child && router.child.length > 0) {
      router.child.map(child => childRouter.push(child));
    } else {
      parentRouter.push(router);
    }
    return '';
  });
  const routerNeedRender = [...parentRouter, ...childRouter];
  xhtml = routerNeedRender.map(route => {
    return (
      <InStayServiceRoutes
        component={route.component}
        exact={route.exact}
        key={route.id}
        path={`/:slug${route.path}`}
        type={DASHBOARD_TYPE.IN_STAY_SERVICE}
      />
    );
  });
  return xhtml;
}

function Routes() {
  const hotels = useSelector(state => state.auth.hotels);
  const hotel = useSelector(state => state.auth.hotel);
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const token = localStorage.getItem(process.env.TOKEN);
  const [isLoading, setIsLoading] = useState(true);

  const getSlugHotel = () => {
    const { pathname } = location;
    const splitPathName = pathname.split('/');
    return splitPathName['1'];
  };

  const checkWebviewRouter = slug => {
    return slug === 'webview';
  };

  useEffect(() => {
    if (token) {
      axiosService.setHeader('Authorization', `Bearer ${token}`);
    }
  }, [token]);

  useEffect(() => {
    const initializeApp = async () => {
      const slug = getSlugHotel();
      
      if (slug === 'privacy' || checkWebviewRouter(slug)) {
        setIsLoading(false);
        return;
      }

      if (!token && slug === '') {
        history.push('/login');
        setIsLoading(false);
        return;
      }

      if (token && !hotel && hotels.length === 0 && !user) {
        try {
          await dispatch(authActions.getInfo(slug));
        } catch (error) {
          console.error('Failed to load hotel info:', error);
        }
      }
      
      setIsLoading(false);
    };

    initializeApp();
  }, [token, hotel, hotels, user]);

  if (isLoading || (token && !hotel && !checkWebviewRouter(getSlugHotel()))) {
    return (
      <Suspense fallback={<Loader />}>
        <Loader />
      </Suspense>
    );
  }

  if (!token || checkWebviewRouter(getSlugHotel()) || getSlugHotel() === 'privacy') {
    return (
      <Suspense fallback={<Loader />}>
        <ToastContainer />
        <Switch>
          {renderPublicRoutes()}
          {renderWebviewRoutes()}
          <Route path="/privacy/" component={Privacy} />
        </Switch>
      </Suspense>
    );
  }

  // Render các route cần hotel
  return (
    <Suspense fallback={<Loader />}>
      <ToastContainer />
      {hotel && (
        <Switch>
          {renderLiveChatRoutes()}
          {renderDashboard(hotel)}
          {renderInStayService()}
          <DashboardRoutes path="/" component={lazy(() => import('../../view/Dashboard'))} exact />
          <Route path="/webview/payment-success/" component={PaymentSuccess} />
          {renderPublicRoutes()}
          {renderWebviewRoutes()}
          <Route path="/privacy/" component={Privacy} />
          <Route component={PageNotFound} path="*" />
        </Switch>
      )}
    </Suspense>
  );
}

Routes.propTypes = {
  component: PropTypes.object,
};

export default Routes;
