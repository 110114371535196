import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';
import lineIcon from '../assets/images/lineIcon.svg';
import messengerIcon from '../assets/images/messengerIcon.svg';
import React from 'react';

export const formatDate = date => {
  const d = formatStandardDate(date);
  let month = d.getMonth() + 1;
  let day = d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

export const formatStandardDate = date => {
  if (typeof date === 'string') {
    const replaceDate = date.replace(/-/g, '/');
    return new Date(replaceDate);
  }
  return new Date(date);
};

export const formatDateForShow = date => {
  const dateStandard = formatStandardDate(date);
  const day = String(dateStandard.getDate()).padStart(2, '0');
  const month = String(dateStandard.getMonth() + 1).padStart(2, '0');
  const year = dateStandard.getFullYear();
  return [day, month, year].join('/');
};

export const formatVND = price => {
  price = Math.trunc(price);
  return price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

export const formatPriceForShow = (price, currency) => {
  price = formatVND(price);
  return currency === 'VND' ? `${price} ${currency}` : `${currency} ${price}`;
};

export const formatTimeShow = date => {
  const today = formatStandardDate(date);
  const h = String(today.getHours()).padStart(2, '0');
  const m = String(today.getMinutes() + 1).padStart(2, '0');
  return `${h}:${m}`;
};

export const formatDateTimeShow = dateUTC => {
  if (dateUTC) {
    const date = formatTimeZone(dateUTC);
    const dateShow = formatDateForShow(date);
    const timeShow = formatTimeShow(date);
    return `${dateShow} ${timeShow}`;
  }
  return '';
};

export const formatTimeZone = date => {
  const now = new Date();
  let offsetInHours = now.getTimezoneOffset() / 60;
  offsetInHours *= -1;
  const d = formatStandardDate(date);
  d.setTime(d.getTime() + offsetInHours * 60 * 60 * 1000);
  return d;
};

export const findGetParameter = parameterName => {
  let result = null;
  let tmp = [];
  const params = window.location.search.substr(1).split('&');
  params.forEach(item => {
    tmp = item.split('=');
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  });
  return result;
};

export const refreshData = model => {
  model.forEach(input => {
    if (input.type === 'file') {
      input.file = '';
    }
    if (input.type === 'multipleFile') {
      input.gallery = [];
      input.deleted_gallery = [];
    }
    if (input.translations) {
      input.translations = {};
    }
    input.value = input.defaultValue;
  });
  return model;
};

export const getErrorApi = error => {
  const { response } = error;
  const { errors } = response.data;
  const mess = Object.values(errors);
  const errorString = mess.reduce((r, v) => r.concat(`${v}<br />`), '');
  return ReactHtmlParser(errorString);
};

export const getTotalNight = (toDate, fromDate) => {
  const today = formatStandardDate(toDate);
  const fromDay = formatStandardDate(fromDate);
  const differenceInTime = today.getTime() - fromDay.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return differenceInDays;
};

export const formatTimeFromFacebook = date => {
  const d = new Date(date);
  return formatDateTimeShow(d);
};

export const sendMessageToSlack = error => {
  if (process.env.PLATFORM !== 'local') {
    axios.post(
      process.env.SLACK_WEBHOOK,
      JSON.stringify({
        text: error,
      }),
    );
  }
};

export const makeObjectLanguageForModify = (data, language) => {
  const dataLang = {};
  language.map(lang => {
    dataLang[lang] = data?.[lang] || '';
  });
  return dataLang;
};

export const makeObjectLanguageForApi = (data, name, language) => {
  const dataLang = {};
  language.map(lang => {
    dataLang[lang] = data?.[`${name}_${lang}`] || '';
  });
  return dataLang;
};

export const makeDefaultObjectForReset = data => {
  const defaultObj = {};
  Object.keys(data).map(key => (defaultObj[key] = null));
  return defaultObj;
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getSectionSlugFromLocation = location => {
  const path = location.path.split('/in-stay-service/')[1];
  if (path.includes('/')) {
    const section = path.substr(0, path.indexOf('/'));
    return section;
  }

  return path;
};

export const getCountryNameFromCurrency = currency => {
  switch (currency) {
    case 'IDR': 
      return 'Indonesia';
    case 'THB': 
      return 'Thailand';
    default: 
      return 'Vietnam';
  }
}

export const getChannelName = (channel, name) => {
  switch (channel) {
    case 'facebook':
      return (
        <span>
          <img src={messengerIcon} alt="fb_icon" width="15px" /> {name}
        </span>
      );
    case 'line':
      return (
        <span>
          <img src={lineIcon} alt="line_icon" width="15px" /> {name}
        </span>
      );
    default:
      return null;
  }
};

export const getPriceUnitFromCurrency = (currency) => {
  switch (currency) {
    case 'IDR': 
      return '1 IDR unit';
    case 'THB': 
      return '1 THB unit';
    case 'USD':
      return '1 USD unit';  
    default: 
      return '1000 VND unit';
  }
}